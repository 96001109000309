<template>
  <el-dialog v-dialogDrag title="确认下单？"  :close-on-click-modal="false" :visible.sync="dialogVisible"  :before-close="handleClose" width="360px">
    <div class="orderConfirm">
      <div class="confirmList">
        <div class="weightBox">
           <h5 style="line-height:50px">摘单重量：</h5>  
             <el-input v-model="pickResourcesWeight" :disabled="isTradeWay" @input="inputBlur" @blur="inputPrice" :placeholder="weightPlaceHolder"></el-input>
        </div>
        <!-- <p class="listTitle box1">可摘单重量：{{this.unsettledWeight-this.frozenWeight}}{{this.orderDetails.weightUnitName}}</p> -->
        <p class="listTitle box2">履约订金：{{marginPrice > 0 ? marginPrice : '---'}}元</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="isLoadingOrder" @click="goOrder()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() { 
    return {
      id: null,
      dialogVisible: false,
      isLoadingOrder: false,
      unsettledWeight:0,
      frozenWeight:0,
      basisPrice:0,
      orderDetails:{},
      marginRate: window.g.marginRate,
      right: {
        moneyBuy: false
      },
      pickResourcesWeight:null,
      weightPlaceHolder:'',
      isTradeWay: false,
    };
  },

  computed:{
    ...mapGetters(["getMarginRateGetter"]),
    marginPrice(){
      // return parseInt(Number(this.pickResourcesWeight)*Number(this.basisPrice)*this.getMarginRateGetter.marginRate);
      // return parseInt(Number(this.pickResourcesWeight)*Number(this.basisPrice)*0.15);
        return Math.round(Number(this.pickResourcesWeight)*Number(this.basisPrice)*Number(this.marginRate)*100)/100;
    }
  },
  methods: {
    inputPrice(){
      let reg = /^\d+(\.\d{0,2})?$/;
      if(!reg.test(this.pickResourcesWeight) || Number(this.pickResourcesWeight) <= 0){
        return this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
      }
    },
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.moneyBuy = this.isHasRight('basis-trade-pickBuyMarginResource');
        }
      })
    },
    inputBlur(value){
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return this.$EL_MESSAGE("请输入摘单重量");
      } else if (!reg.test(value)) {
        return  this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
      } else if(Number(this.pickResourcesWeight)>(Number(this.unsettledWeight)-Number(this.frozenWeight))){
        return  this.$EL_MESSAGE("重量不能超过可摘单重量");
      }
    },
    showDialog(row) {
      this.orderDetails = row;
      this.unsettledWeight = row.unsettledWeight;
      this.frozenWeight = row.frozenWeight;
      this.basisPrice = row.basisPrice;
      this.id = row.hangResourcesId;
      let canPickWeight = Number(row.unsettledWeight)-Number( row.frozenWeight)
      if(row.tradeWay=='P'){
        this.weightPlaceHolder = '可摘单重量：' + canPickWeight + row.weightUnitName
        this.isTradeWay = false
      
      }else{
        this.weightPlaceHolder = ''
        this.pickResourcesWeight = canPickWeight
        this.isTradeWay = true
      }
      this.dialogVisible = true;
      this.getRights();
    },
    handleClose() {
      this.pickResourcesWeight = null;
      this.dialogVisible = false;
      this.pickResourcesWeight = null;
    },
    goOrder() {
       let reg = /^\d+(\.\d{0,2})?$/;
      if(!this.pickResourcesWeight){
        this.$EL_MESSAGE('请输入摘单重量')
        return
      }else if(!reg.test(this.pickResourcesWeight) || Number(this.pickResourcesWeight) <= 0){
        return this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
        
      }
      if (this.right.moneyBuy) {
        this.isLoadingOrder = true;
        protocolFwd.param_pickBuyBasisMarginResource.param.hangResourcesId = this.id;
        protocolFwd.param_pickBuyBasisMarginResource.param.pickResourcesWeight = this.pickResourcesWeight;
        http.postFront(protocolFwd.param_pickBuyBasisMarginResource).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.pickResourcesWeight = null
              this.$confirm("查看订单", "下单成功", {
                distinguishCancelAndClose: true,
                confirmButtonText: "去看看",
                cancelButtonText: "取消"
              }).then(() => {
                  this.$router.push({
                    path: "/buyerCenter/purchase/basisOrder"
                  })}).catch(action => {});
              this.isLoadingOrder = false;
            } else {
              this.pickResourcesWeight = null
              this.$EL_MESSAGE(message);
              this.isLoadingOrder = false;
            }
            this.dialogVisible = false;
          })
          .catch(() => {
            this.pickResourcesWeight = null
            this.isLoadingOrder = false;
          });
      } else {
        this.pickResourcesWeight = null
        this.$EL_MESSAGE("您无操作权限");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.orderConfirm .confirmList .weightBox{
  margin-bottom: 15px;
}
.orderConfirm .confirmList .listTitle{
  font-size: 14px;  
  margin:6px 0;
}

</style>