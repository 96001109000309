<template>
  <div class="accountInfo">
    <div class="accountInfoTitle">
      <span>经营产品信息</span>
    </div>
    <div class="btnAction">
      <span class="tip">温馨提示：生产厂家、产地、品牌、资质证书、品牌授权新增或编辑完毕后，需点击提交按钮进入审核流程</span>
        <!-- <el-button type="warning" v-if="!isExist" @click="addInfo()">新增</el-button> -->
        <!-- <el-button type="primary" v-if="isExist" @click="editInfo()">编辑</el-button> -->
        <!-- <el-button type="warning" v-if="isExist" @click="resetInfo()">重置</el-button> -->
        <el-button type="danger" @click="submitApply()">提交</el-button>
    </div>
    <div v-if="form.status!=4">
      <div class="hasIdentification">
        <el-alert
          class="auditResult"
          v-if="form.status==2"
          title="审核结果：您的经营产品信息审核不通过"
          :description="'审核意见：' + form.note"
          type="error">
        </el-alert>
      </div>
      <div v-if="form.status == 0" class="hasIdentification">
        <el-alert title="您的经营产品信息处于待审核状态" type="warning" :closable="false"></el-alert>
      </div>
      <div v-if="form.status == 1 " class="hasIdentification">
        <el-alert title="您的经营产品信息审核已通过" type="success" :closable="false"></el-alert>
      </div>
    </div>
    <div class="accountInfoContent">
      <el-row :gutter="20">
        <el-col :span="7" :offset="1">
           <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>生产厂家</span>
              <i style="float: right; padding: 10px 0;font-weight: bold;" class="el-icon-plus" @click="addInfo(1)"></i>
            </div>
            <div class="companyInfo">
              <div v-for="(item,indexs) of companyList" :key="indexs">
                 <el-input v-model="item.name" placeholder="请输入生产厂家缩写" clearable  @blur="checkInfo(item,1)"></el-input>
                 <i class="el-icon-delete" @click="deleteRow(indexs,1)"></i>
                 <p class="tip">{{item.messages}}</p>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="7">
           <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>产地</span>
               <i style="float: right; padding: 10px 0;font-weight: bold;" class="el-icon-plus" @click="addInfo(2)"></i>
            </div>
            <div class="companyInfo">
              <div v-for="(item,indexs) of areaList" :key="indexs">
                 <el-input v-model="item.name" placeholder="请输入产地" clearable @blur="checkInfo(item,2)"></el-input>
                 <i class="el-icon-delete" @click="deleteRow(indexs,2)"></i>
                 <p class="tip">{{item.messages}}</p>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="7">
           <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>品牌</span>
               <i style="float: right; padding: 10px 0;font-weight: bold;" class="el-icon-plus" @click="addInfo(3)"></i>
            </div>
            <div class="companyInfo">
              <div v-for="(item,indexs) of brandList" :key="indexs">
                 <el-input v-model="item.name" placeholder="请输入品牌" clearable @blur="checkInfo(item,3)"></el-input>
                 <i class="el-icon-delete" @click="deleteRow(indexs,3)"></i>
                  <p class="tip">{{item.messages}}</p>
              </div>
            </div>
          </el-card>
        </el-col>    
      </el-row>
      <el-row class="topClass">
         <el-col :span="21" :offset="1">
           <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>资质证书</span>
               <!-- <i style="float: right; padding: 10px 0;font-weight: bold;" class="el-icon-plus" @click="addInfo(3)"></i> -->
            </div>
            <div class="companyInfos">
             <el-upload
              :file-list="newImageUrl"
              :action="actionUrl"
              list-type="picture-card"
              multiple
              :limit="10"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :on-exceed="handleExceed"
              >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogcImageUrl" alt="">
            </el-dialog>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="topClass">
         <el-col :span="21" :offset="1">
           <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>品牌授权</span>
               <!-- <i style="float: right; padding: 10px 0;font-weight: bold;" class="el-icon-plus" @click="addInfo(3)"></i> -->
            </div>
            <div class="companyInfos">
             <el-upload
              :file-list="newImageSqUrl"
              :action="actionUrl"
              list-type="picture-card"
              :limit="10"
              multiple
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemovesq"
              :on-success="handleSuccesss"
              :on-exceed="handleExceed">
              <i class="el-icon-plus"></i>
            </el-upload>
            </div>
          </el-card>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import confirmOrderVue from '../../BasisTrading/confirmOrder.vue';
export default {
  data() {
    return {
      isExist:true,
      companyList:[],
      areaList:[],
      brandList:[],
      infoId:null,
      form:{
        status:4,
        note:'--',
        id:null
      },
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null,
      },
      dialogImageSqUrl:[],
      dialogImageUrl: [],
      newImageSqUrl: [],
      newImageUrl: [],
      dialogcImageUrl: '',
      dialogVisible: false
   //   isFirstWhite:false // 厂家是否可为空,该企业在数据库中没有填写过，第一次填写厂家和品牌可为空，，第二次填写，之前如果有输入过厂家，厂家就不能为空
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getInformation();
  },
  methods: {
    handleRemove(file) {
      let index = this.dialogImageUrl.indexOf(file.url);
      this.dialogImageUrl.splice(index, 1);
    },
    handleRemovesq(file){
      let index = this.dialogImageSqUrl.indexOf(file.url);
      this.dialogImageSqUrl.splice(index, 1);
    },
    handlePictureCardPreview(file) {
      this.dialogcImageUrl = file.url;
      this.dialogVisible = true;

    },
    handleSuccess(response,file,fileList){
      if (response.code=='0') {
        this.dialogImageUrl.push(response.value);
      } else {
        fileList.pop()
        this.$EL_MESSAGE(response.message)
      }
    },
    handleSuccesss(response,file,fileList){
      if (response.code=='0') {
        this.dialogImageSqUrl.push(response.value);
      } else {
        fileList.pop()
        this.$EL_MESSAGE(response.message)
      }
    },
    handleExceed(files, fileList){
      this.$EL_MESSAGE('最多上传10张')
    },
    queryMe(companyName,list){
      // if(list==this.companyList&&companyName==''){
      //   this.isFirstWhite = true
      // }
      if(companyName&&companyName.indexOf(',')!=-1){
         let newCompany = companyName.split(',')
          for(let i=0;i<newCompany.length;i++){
            let obj = {'name':newCompany[i],'messages':''}
            list.push(obj)
          }
      }else if(companyName&&companyName.indexOf(',')==-1){
        let obj = {'name':companyName,'messages':''}
        list.push(obj)
      }else{
        list = []
      }
    },
    queryPhoto(urlList,list){ // 处理图片
      if(urlList&&urlList.indexOf(',')!=-1){
         let newCompany = urlList.split(',')
          for(let i=0;i<newCompany.length;i++){
            let obj = {'url':newCompany[i]}
            list.push(obj)
          }
      }else if(urlList&&urlList.indexOf(',')==-1){
        let obj = {'url':urlList}
        list.push(obj)
      }else{
        list = []
      }
    },
    getInformation(){
      this.dialogImageSqUrl = []
      this.dialogImageUrl = []
      this.companyList = []
      this.brandList = []
      this.areaList = []
      protocolFwd.param_queryInformation.param.firmId = sessionStorage.getItem('userFirmId')
      http.postFront(protocolFwd.param_queryInformation).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if(value){
            this.form = value
            this.queryMe( value.manufacturer,this.companyList)
            this.queryMe( value.brand,this.brandList)
            this.queryMe( value.placeOrigin,this.areaList)
            this.queryPhoto( value.proveContentSq,this.newImageSqUrl)
            this.queryPhoto( value.proveContentZs,this.newImageUrl)
            if(value.proveContentSq.indexOf(',')!=-1){
              this.dialogImageSqUrl = value.proveContentSq.split(',')
            } else if(value.proveContentSq!=''){
              this.dialogImageSqUrl.push(value.proveContentSq)
            }else{
              this.dialogImageSqUrl = []
            }
            if(value.proveContentZs.indexOf(',')!=-1){
              this.dialogImageUrl = value.proveContentZs.split(',')
            } else if(value.proveContentZs!=''){
              this.dialogImageUrl.push(value.proveContentZs)
            }else{
              this.dialogImageUrl = []
            }
          }
          if(value==null){
            // this.isFirstWhite = true
            this.form={
              status:4,
              note:'--',
              id:null
            }
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    addInfo(id){
      if(id==1){
        let obj = {name:'',messages:''}
        this.companyList.push(obj);
      }else if(id==2){
        let obj = {name:'',messages:''}
        this.areaList.push(obj);
      }else if(id==3){
        let obj = {name:'',messages:''}
        this.brandList.push(obj);
      }
    },
    deleteRow(index,number){
      if(number==1){
        this.companyList.splice(index,1);
      }else if(number==2){
        this.areaList.splice(index,1);
      }else if(number==3){
        this.brandList.splice(index,1);
      }
    },
    checkInfo(info,index){
      if(!(info.name)){
        if(index==1){
          info.messages = '请输入生产厂家缩写'
        }else if(index==2){
          info.messages = '请输入产地'
        }else if(index==3){
          info.messages = '请输入品牌'
        }else{
          info.messages = '请重新输入'
        }
      }else{
        info.messages = ''
      }
    },
    submitApply(){
      let companySx = '' // 厂家缩写拼接的字符串
      let areaSplice = ''
      let brandSplice  = ''
      let isAllCompanyList = false
      let isAllAreaList = false
      let isAllBrandList = false
      if(this.companyList.length!=0){
        this.companyList.forEach(element=>{
          if(element.name!=''){
            if(companySx==''){
              companySx+=element.name
            }else{
              companySx=companySx+','+element.name
            }
             isAllCompanyList = true
          }else{
            this.$EL_MESSAGE('请输入生产厂家缩写或删除多余输入框')
            isAllCompanyList = false
            return false
          }
        })
      }else{
        isAllCompanyList = true
      }
      // else if(this.isFirstWhite){
      //   isAllCompanyList = true
      // }
      if(this.areaList.length!=0){
        this.areaList.forEach(element=>{
          if(element.name!=''){
            if(areaSplice==''){
               areaSplice+=element.name
            }else{
              areaSplice=areaSplice+','+element.name
            }
            isAllAreaList = true
          }else{
            this.$EL_MESSAGE('请输入产地或删除多余输入框')
            isAllAreaList = false
            return false
          }
        })
      }else{
        this.$EL_MESSAGE('请输入产地(至少输入一个产地)')
        isAllAreaList = false
        return false
      }
      if(this.brandList.length!=0){
        this.brandList.forEach(element=>{
          if(element.name!=''){
            if(brandSplice==''){
               brandSplice+=element.name
            }else{
              brandSplice=brandSplice+','+element.name
            }
             isAllBrandList = true
          }else{
            this.$EL_MESSAGE('请输入品牌或删除多余输入框')
            isAllBrandList = false
            return false
          }
        })
      }else{
         isAllBrandList = true
      }
      if(isAllCompanyList&&isAllAreaList&&isAllBrandList){
          let params = {
          firmId: sessionStorage.getItem('userFirmId'),//企业Id(修改必传)
          id: this.form.id,//经营产品信息主键(修改必传)
          manufacturer: companySx,
          placeOrigin: areaSplice,// 产地
          brand: brandSplice,
          proveContentZs: this.dialogImageUrl.join(),
          proveContentSq: this.dialogImageSqUrl.join(),
          status: 0 //审核状态: 0-已申请, 1-通过,2-驳回
        }
        protocolFwd.param_addInformation.param = params
        http.postFront(protocolFwd.param_addInformation).then(response => {
          const { code, message, value } = response.data;
          this.$EL_MESSAGE(message);
          if (code == 0) {
            this.companyList = []
            this.areaList = []
            this.brandList = []
            this.newImageUrl = []
            this.newImageSqUrl = []
            this.getInformation();
          }
        });
      }
    },
  
  }
};
</script>
<style lang="scss" scoped>
.accountInfo {
  line-height: 40px;
}
.accountInfo .accountInfoTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.accountInfo .accountInfoTitle span {
  display: inline-block;
  padding: 0 15px;
  font-size: 16px;
  // border-bottom: 2px solid $commonThemeColor;
}
.accountInfoContent {
  width: 100%;
  margin: 15px;
  line-height: 40px;
}
.topClass{
  margin-top:20px;
}
.companyInfo{
  height:300px;
  overflow-y:auto;
}
.btnAction{
  .tip{
    display: inline-block;
    text-align:left;
    color:#cf4640;
    width:90%;
    font-size:15px;
  }
  text-align: right;
  margin:15px;
}
.tip{
  font-size: 12px;
  line-height: 20px;
  color:#cf4640;
}
.hasIdentification {
  margin: 20px 30px 0 30px;
}
</style>
